<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="emit('close', false)">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
              >
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    @click="emit('close', false)"
                  >
                    <icon name="heroicons:x-mark-solid" class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="px-4 pt-5 pb-4 sm:p-6">
                  <DialogTitle as="h3" class="font-trajan text-2xl text-red-600 mt-2 mb-2">{{ title }}</DialogTitle>
                  <div v-html="context" class="mt-4"></div>
                </div>
                <div v-if="ok" class="border-t border-gray-500 text-center py-6">
                  <button
                    @click="emit('close', false)"
                    class="font-proxima text-white text-sm font-semibold px-6 py-2 rounded-md capitalize bg-red-500 border border-gray-600"
                  >
                    Ok
                  </button>
                </div>
                <div v-else class="border-t border-gray-500 text-center py-6">
                  <button
                    @click="emit('close', false)"
                    class="font-proxima text-black text-sm font-semibold px-6 py-2 rounded-md capitalize border border-gray-600 mr-4"
                  >
                    Cancel
                  </button>
                  <button
                    @click="
                      emit('confirm');
                      disabledBtn = true;
                    "
                    class="font-proxima text-white text-sm font-semibold px-6 py-2 rounded-md capitalize bg-red-500 border border-gray-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                    :disabled="disabledBtn"
                  >
                    Confirm
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
interface Props {
  open: boolean;
  title?: string;
  context?: string;
  ok?: boolean;
  disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  open: false,
  title: 'Are you sure?',
  disabled: false,
});

const emit = defineEmits(['close', 'confirm']);
const disabledBtn = ref(props.disabled);
</script>
